import { Token, WNATIVE } from './entities/token'
import {
  ChainId,
  FACTORY_ADDRESS_MAP,
  INIT_CODE_HASH_MAP,
  MULTICALL_ADDRESS_MAP,
  MULTICALL_BLOCK_CREATE_MAP,
  ROUTER_ADDRESS_MAP,
} from './constants'
import { RPC_URLS } from './rpc'

export type MulticallType = {
  address: string
  blockCreated: number
  INIT_CODE_HASH: string
}
export type NativeCurrencyType = {
  name: string
  symbol: string
  decimals: number
}
export interface ChainType {
  name: string
  chainId: number
  network: string
  isMainnet?: boolean
  rpcUrls: {
    default: string
    public: string[]
  }
  scanURL: string
  nativeCurrency: NativeCurrencyType
  wNativeCurrency: Token
  routerAddress: string
  factoryAddress: string
  multicall: {
    address: string
    blockCreated: number
    INIT_CODE_HASH: string
  }
}

/* RPC Chain list */
export const BASE_BSC_SCAN_URLS = {
  [ChainId.BSC]: 'https://bscscan.com',
  [ChainId.DX_CHAIN]: 'https://dxdscan.com',
}

// export const CHAIN_LIST: { [chainId in ChainId]: ChainType } = {
export const CHAIN_LIST: { [chainId: number]: ChainType } = {
  [ChainId.DX_CHAIN]: {
    name: 'DxD Chain Mainnet',
    chainId: ChainId.DX_CHAIN,
    network: 'dxd',
    isMainnet: true,
    rpcUrls: {
      default: RPC_URLS[ChainId.DX_CHAIN][0],
      public: RPC_URLS[ChainId.DX_CHAIN],
    },
    scanURL: BASE_BSC_SCAN_URLS[ChainId.DX_CHAIN],
    nativeCurrency: {
      name: 'DXD',
      symbol: 'DXD',
      decimals: 18,
    },
    wNativeCurrency: WNATIVE[ChainId.DX_CHAIN],
    routerAddress: ROUTER_ADDRESS_MAP[ChainId.DX_CHAIN],
    factoryAddress: FACTORY_ADDRESS_MAP[ChainId.DX_CHAIN],
    multicall: {
      address: MULTICALL_ADDRESS_MAP[ChainId.DX_CHAIN],
      blockCreated: MULTICALL_BLOCK_CREATE_MAP[ChainId.DX_CHAIN],
      INIT_CODE_HASH: INIT_CODE_HASH_MAP[ChainId.DX_CHAIN],
    },
  },
}
