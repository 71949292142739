function _taggedTemplateLiteral(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    return Object.freeze(Object.defineProperties(strings, {
        raw: {
            value: Object.freeze(raw)
        }
    }));
}
function _templateObject() {
    var data = _taggedTemplateLiteral([
        "\n  ",
        "\n  ",
        "\n  ",
        " \n  ",
        "\n  ",
        "\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _taggedTemplateLiteral([
        "\n  ",
        "\n  ",
        "\n  ",
        "\n  ",
        "\n  ",
        "\n  ",
        "\n  ",
        "\n  ",
        "\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import { motion } from "framer-motion";
import styled from "styled-components";
import { background, border, layout, position, space, color, boxShadow, flexGrow } from "styled-system";
export var MotionBox = styled(motion.div).withConfig({
    componentId: "sc-6deafb0b-0"
})(_templateObject(), background, border, layout, position, space);
var Box = styled.div.withConfig({
    componentId: "sc-6deafb0b-1"
})(_templateObject1(), background, border, boxShadow, layout, position, space, color, flexGrow);
export default Box;
