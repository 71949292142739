import { ChainId } from './constants'

export const RPC_URLS = {
  [ChainId.BSC]: [
    'https://bsc-dataseed1.binance.org',
    'https://bsc-dataseed1.defibit.io',
    'https://bsc-dataseed1.ninicoin.io',
  ],
  [ChainId.DX_CHAIN]: ['https://node1.dxdchain.com'],
}
