import JSBI from 'jsbi'

// exports for external consumption
export type BigintIsh = JSBI | number | string

export enum ChainId {
  BSC = 56,
  DX_CHAIN = 9000,
}

export enum TradeType {
  EXACT_INPUT,
  EXACT_OUTPUT,
}

export enum Rounding {
  ROUND_DOWN,
  ROUND_HALF_UP,
  ROUND_UP,
}

export const FACTORY_ADDRESS_MAP = {
  [ChainId.BSC]: '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73',
  [ChainId.DX_CHAIN]: '0x31C52Ba6e8B49f392f3eE08551Bad6B38BEF1fac',
}

export const ROUTER_ADDRESS_MAP = {
  [ChainId.BSC]: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
  [ChainId.DX_CHAIN]: '0x183C9A41d985D53C1A7a7493E6DedfFdf78FBCD2',
}

export const MULTICALL_ADDRESS_MAP = {
  [ChainId.BSC]: '',
  [ChainId.DX_CHAIN]: '0x57699A527E8fE9deA95df588c9fB1Ac748E551f4',
}

// Multicall block number
export const MULTICALL_BLOCK_CREATE_MAP = {
  [ChainId.BSC]: 841910,
  [ChainId.DX_CHAIN]: 19371,
}

export const INIT_CODE_HASH_MAP = {
  [ChainId.BSC]: '0x00fb7f630766e6a796048ea87d01acd3068e8ff67d078148a3fa3f4a84f69bd5',
  [ChainId.DX_CHAIN]: '0xe42e4b5a3a1d1a987dab24ee835bf4ce523f8e567b8def7b3c5d47d96fe572af',
}

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const TWO = JSBI.BigInt(2)
export const THREE = JSBI.BigInt(3)
export const FIVE = JSBI.BigInt(5)
export const TEN = JSBI.BigInt(10)
export const _100 = JSBI.BigInt(100)
export const FEES_NUMERATOR = JSBI.BigInt(9975)
export const FEES_DENOMINATOR = JSBI.BigInt(10000)

export enum SolidityType {
  uint8 = 'uint8',
  uint256 = 'uint256',
}

export const SOLIDITY_TYPE_MAXIMA = {
  [SolidityType.uint8]: JSBI.BigInt('0xff'),
  [SolidityType.uint256]: JSBI.BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'),
}
