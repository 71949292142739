function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
import { scales, variants } from "./types";
var _obj;
export var scaleVariants = (_obj = {}, _defineProperty(_obj, scales.MD, {
    minHeight: "44px",
    padding: "0 24px"
}), _defineProperty(_obj, scales.SM, {
    minHeight: "32px",
    padding: "0 16px"
}), _defineProperty(_obj, scales.XS, {
    minHeight: "20px",
    fontSize: "12px",
    padding: "0 8px"
}), _obj);
var _obj1;
export var styleVariants = (_obj1 = {}, _defineProperty(_obj1, variants.PRIMARY, {
    // backgroundColor: "primary",
    backgroundImage: "linear-gradient(90deg, #6B25B5 0%, #C54A89 105.52%)",
    ":hover:not(:disabled)": {
        backgroundImage: "linear-gradient(90deg, #451974 0%, #8B3461 105.52%)"
    },
    color: "white"
}), _defineProperty(_obj1, variants.SECONDARY, {
    backgroundColor: "transparent",
    border: "1px solid",
    borderColor: "text",
    boxShadow: "none",
    color: "text",
    ":disabled": {
        backgroundColor: "transparent"
    },
    ":hover": {
        color: "#D7BEF2",
        borderColor: "#D7BEF2"
    }
}), _defineProperty(_obj1, variants.TERTIARY, {
    backgroundColor: "primary",
    boxShadow: "none",
    color: "textWhite"
}), _defineProperty(_obj1, variants.TERTIARY2, {
    backgroundColor: "#FFFFFF99",
    border: "1px solid #6C25B4",
    boxShadow: "none",
    color: "primary"
}), _defineProperty(_obj1, variants.SUBTLE, {
    backgroundColor: "textSubtle",
    color: "backgroundAlt"
}), _defineProperty(_obj1, variants.DANGER, {
    backgroundColor: "failure",
    color: "white"
}), _defineProperty(_obj1, variants.SUCCESS, {
    backgroundColor: "success",
    color: "white"
}), _defineProperty(_obj1, variants.TEXT, {
    backgroundColor: "transparent",
    color: "text",
    boxShadow: "none"
}), _defineProperty(_obj1, variants.LIGHT, {
    backgroundColor: "input",
    color: "textSubtle",
    boxShadow: "none"
}), _defineProperty(_obj1, variants.WHITE, {
    backgroundColor: "#ffffff",
    border: "1px solid",
    borderColor: "#ffffff",
    color: "primary",
    boxShadow: "none",
    ":hover": {
        color: "#D7BEF2",
        borderColor: "#D7BEF2"
    }
}), _obj1);
