function _taggedTemplateLiteral(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    return Object.freeze(Object.defineProperties(strings, {
        raw: {
            value: Object.freeze(raw)
        }
    }));
}
function _templateObject() {
    var data = _taggedTemplateLiteral([
        "\n  position: relative;\n  background: ",
        ";\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _taggedTemplateLiteral([
        "\n  list-style: none;\n  margin-bottom: 20px;\n  order: 2;\n\n  ",
        " {\n    margin-bottom: 0px;\n    order: 1;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _taggedTemplateLiteral([
        "\n  margin-bottom: 8px;\n  text-transform: capitalize;\n\n  &:first-child {\n    font-size: 20px;\n    color: #8c45d5;\n    margin-bottom: 28px;\n    font-weight: 600;\n    text-transform: uppercase;\n  }\n\n  > a {\n    font-size: 16px;\n\n    ",
        " {\n      font-size: 20px;\n    }\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _taggedTemplateLiteral([
        "\n  margin-bottom: 24px;\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _taggedTemplateLiteral([
        "\n  ",
        " {\n    margin-top: 28px;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _taggedTemplateLiteral([
        "\n  border-bottom: 1px solid ",
        ";\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _taggedTemplateLiteral([
        "\n  color: ",
        ";\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _taggedTemplateLiteral([
        "\n  position: absolute;\n  top: -60px;\n  right: 0;\n\n  img {\n    width: 100px;\n    object-fit: contain;\n  }\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _taggedTemplateLiteral([
        "\n  margin-bottom: 16px;\n\n  ",
        " {\n    position: absolute;\n    bottom: calc(100% + 24px);\n    right: 0;\n    left: unset;\n    margin-bottom: 0;\n  }\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { darkColors, lightColors } from "../../theme/colors";
import { Box, Flex } from "../Box";
import SocialLinks from "./Components/SocialLinks";
export var StyledFooter = styled(Flex).withConfig({
    componentId: "sc-e505334b-0"
})(_templateObject(), lightColors.backgroundAlt);
export var StyledList = styled.ul.withConfig({
    componentId: "sc-e505334b-1"
})(_templateObject1(), function(param) {
    var theme = param.theme;
    return theme.mediaQueries.md;
});
export var StyledListItem = styled.li.withConfig({
    componentId: "sc-e505334b-2"
})(_templateObject2(), function(param) {
    var theme = param.theme;
    return theme.mediaQueries.lg;
});
export var StyledIconMobileContainer = styled(Box).withConfig({
    componentId: "sc-e505334b-3"
})(_templateObject3());
export var StyledToolsContainer = styled(Flex).withConfig({
    componentId: "sc-e505334b-4"
})(_templateObject4(), function(param) {
    var theme = param.theme;
    return theme.mediaQueries.md;
});
export var StyledSocialLinks = styled(SocialLinks).withConfig({
    componentId: "sc-e505334b-5"
})(_templateObject5(), darkColors.cardBorder);
export var StyledText = styled.span.withConfig({
    componentId: "sc-e505334b-6"
})(_templateObject6(), lightColors.textWhite);
export var FooterHelper = styled.div.withConfig({
    componentId: "sc-e505334b-7"
})(_templateObject7());
export var FooterEmail = styled.div.withConfig({
    componentId: "sc-e505334b-8"
})(_templateObject8(), function(param) {
    var theme = param.theme;
    return theme.mediaQueries.md;
});
