function _taggedTemplateLiteral(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    return Object.freeze(Object.defineProperties(strings, {
        raw: {
            value: Object.freeze(raw)
        }
    }));
}
function _templateObject() {
    var data = _taggedTemplateLiteral([
        "\n  display: flex;\n  gap: ",
        ";\n  ",
        "\n  ",
        "\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { flexbox } from "styled-system";
import Box from "./Box";
var Flex = styled(Box).withConfig({
    componentId: "sc-244e33f6-0"
})(_templateObject(), function(param) {
    var gap = param.gap;
    return gap || 0;
}, function(param) {
    var center = param.center;
    return center && "\n    align-items: center;\n    justify-content: center;\n  ";
}, flexbox);
export default Flex;
